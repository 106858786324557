
import {
  defineComponent,
  ref,
  PropType,
  computed,
  watch,
  onMounted,
} from "vue";
import { Popover, Icon } from "vant";

export default defineComponent({
  props: {
    initVal: {
      // 初始化试触发 onSelect 事件
      type: Boolean,
      default: false,
    },
    maxLine: {
      // 显示的条目
      type: Number,
      default: 4,
    },
    actions: {
      // 下拉选项
      type: Array as PropType<Array<Action>>,
      default: [],
    },
    theme: {
      // 主题
      type: String,
      default: "light",
    },
    placement: {
      // 显示的方向
      type: String,
      default: "bottom",
    },
    defaultValue: {
      // 初始值
      type: String,
      default: "",
    },
  },
  emits: ["onSelect"],
  components: {
    [Popover.name]: Popover,
    [Icon.name]: Icon,
  },

  setup(props, context) {
    const showPopover = ref(false);
    const text = ref("请选择");
    const currentIndex = ref(-1);

    const initFn = () => {
      let flag = true;
      if (props.defaultValue && props.actions.length > 0) {
        props.actions.forEach((el, index) => {
          if (el.text == props.defaultValue) {
            flag = false;
            if (props.initVal) {
              onSelect(index, props.actions[index]);
            } else {
              text.value = props.defaultValue;
              currentIndex.value = index;
            }
          }
        });
        if (!flag) return;
      }
      if (props.initVal && props.actions.length > 0) {
        onSelect(0, props.actions[0]);
      }
    };

    const onSelect = (index: number, action: Action) => {
      currentIndex.value = index;
      text.value = action.text;
      context.emit("onSelect", index, action);
      showPopover.value = false;
    };

    const getMaxLine = computed(() => {
      const n = props.actions.length;
      if (n == 0) {
        return 1;
      } else if (n > props.maxLine) {
        return props.maxLine;
      } else {
        return n;
      }
    });

    watch(
      () => props.defaultValue,
      () => {
        initFn();
      },
      {
        deep: true,
      }
    );

    watch(
      () => props.actions,
      () => {
        initFn();
      }
    );
    initFn();

    return { text, showPopover, currentIndex, getMaxLine, onSelect };
  },
});
