<template>
  <van-popover
    v-model:show="showPopover"
    class="h-selector"
    :style="{ '--max-heigt': getMaxLine * 30 + 'px' }"
    :theme="theme"
    :placement="placement"
  >
    <template #default>
      <div v-if="actions.length > 0">
        <div
          v-for="(item, index) in actions"
          :key="index"
          :class="[
            'h-selector-item',
            index == currentIndex ? 'h-selector-item__active' : '',
          ]"
          @click="onSelect(index, item)"
        >
          <div class="h-selector-item__text" :title="item.text">
            {{ item.text }}
          </div>
        </div>
      </div>
    </template>
    <template #reference style="height: 100%">
      <div class="h-selector-reference">
        <div class="h-selector-reference__text">
          {{ text }}
          <van-icon
            :name="showPopover ? 'arrow-down' : 'arrow-up'"
            class="h-reference__icon"
          />
        </div>
      </div>
    </template>
  </van-popover>
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  PropType,
  computed,
  watch,
  onMounted,
} from "vue";
import { Popover, Icon } from "vant";

export default defineComponent({
  props: {
    initVal: {
      // 初始化试触发 onSelect 事件
      type: Boolean,
      default: false,
    },
    maxLine: {
      // 显示的条目
      type: Number,
      default: 4,
    },
    actions: {
      // 下拉选项
      type: Array as PropType<Array<Action>>,
      default: [],
    },
    theme: {
      // 主题
      type: String,
      default: "light",
    },
    placement: {
      // 显示的方向
      type: String,
      default: "bottom",
    },
    defaultValue: {
      // 初始值
      type: String,
      default: "",
    },
  },
  emits: ["onSelect"],
  components: {
    [Popover.name]: Popover,
    [Icon.name]: Icon,
  },

  setup(props, context) {
    const showPopover = ref(false);
    const text = ref("请选择");
    const currentIndex = ref(-1);

    const initFn = () => {
      let flag = true;
      if (props.defaultValue && props.actions.length > 0) {
        props.actions.forEach((el, index) => {
          if (el.text == props.defaultValue) {
            flag = false;
            if (props.initVal) {
              onSelect(index, props.actions[index]);
            } else {
              text.value = props.defaultValue;
              currentIndex.value = index;
            }
          }
        });
        if (!flag) return;
      }
      if (props.initVal && props.actions.length > 0) {
        onSelect(0, props.actions[0]);
      }
    };

    const onSelect = (index: number, action: Action) => {
      currentIndex.value = index;
      text.value = action.text;
      context.emit("onSelect", index, action);
      showPopover.value = false;
    };

    const getMaxLine = computed(() => {
      const n = props.actions.length;
      if (n == 0) {
        return 1;
      } else if (n > props.maxLine) {
        return props.maxLine;
      } else {
        return n;
      }
    });

    watch(
      () => props.defaultValue,
      () => {
        initFn();
      },
      {
        deep: true,
      }
    );

    watch(
      () => props.actions,
      () => {
        initFn();
      }
    );
    initFn();

    return { text, showPopover, currentIndex, getMaxLine, onSelect };
  },
});
</script>

<style lang="less">
.h-selector {
  .van-popover__content {
    overflow: auto !important;
    height: var(--max-heigt);
  }
  .h-selector-item__active {
    background-color: skyblue;
  }
}
</style>

<style lang="less" scoped>
.h-selector-item {
  padding: 0 20px;
  min-height: 30px;
  min-width: 100px;
  max-width: 250px;
  .h-selector-item__text {
    padding: 5px 0;
    border-bottom: 1px solid rgba(60, 60, 60, 0.26);
  }
}
.h-selector-reference {
  height: 100%;
  padding: 0 16px;
  border: 1px solid rgba(60, 60, 60, 0.26);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  .h-selector-reference__text {
    height: 30px;
    line-height: 30px;
  }
  .h-selector-reference__icon {
    // margin-left: 10px;
    text-align: right;
  }
}
</style>